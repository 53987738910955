import styled from "styled-components";

export const ResultSidebarWrap = styled.div`
    margin: 0 auto;
    position: relative;
    max-width: 1050px;
`

export const SiteInner = styled.div`
    &:before {
        content: " ";
	    display: table;
    }

    &:after {
        clear: both;
        content: " ";
        display: table;
    }

    clear: both;
	margin: 0 auto;
	padding: 40px 0 0;
    max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
    position: relative;
    min-height: 850px;

    @media only screen and (max-width: 1360px) {
	    max-width: 1140px;
    }
    @media only screen and (max-width: 1250px) {
        max-width: 960px;
    }
    @media only screen and (max-width: 1075px) {
        padding-left: 5%;
		padding-right: 5%;
		max-width: none;
    }
    @media only screen and (max-width: 450px) {
        padding-left: 1.5%;
        padding-right: 1.5%;
        max-width: none;
    }
    
`

export const ResultsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  max-width: 750px !important;
  float: left;
  overflow: hidden;

  @media only screen and (max-width: 1250px) {
    float: none;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }
`